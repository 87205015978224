<template>
    <div class="web-root">
        <div id="web-serp-wrapper" class="webSerpWrapper">
            <div id="web-serp-main" class="webSerpMain">
                <div id="web-serp-results" class="webSerpResults">
                    <div
                        v-if="searchCompleted && topAds"
                        id="special-results-wrapper-top"
                        class="specialResultsWrapper"
                    >
                        <div
                            id="special-top-results-title"
                            class="specialResultsTitle"
                        >
                            {{ getAdsTitleText() }}
                        </div>

                        <div
                            id="special-top-results-inner-wrapper"
                            class="specialResultsInnerWrapper"
                        >
                            <div
                                v-for="(record, index) in topAds"
                                :key="index"
                                :id="`special-top-record_${index}`"
                                class="specialRecord record"
                                @click.prevent="
                                    goToUrl(record.url, true),
                                        sendXMLTracking(2)
                                "
                            >
                                <a
                                    :id="
                                        `special-top-results-primary-area_${index}`
                                    "
                                    class="specialResultsPrimaryArea"
                                    target="_blank"
                                    :href="record.url"
                                >
                                    <div
                                        :id="`special-top-record-url_${index}`"
                                        class="specialRecordUrl recordUrl"
                                    >
                                        {{ record.display_url }}
                                    </div>
                                    <!--official site badge-->
                                    <div
                                        class="official"
                                        v-show="record.official_site"
                                    >
                                        Official Site
                                    </div>
                                    <!--end official site badge-->
                                    <div
                                        :id="
                                            `special-top-record-title_${index}`
                                        "
                                        class="specialRecordTitle recordTitle"
                                    >
                                        <span v-html="record.title"></span>
                                    </div>
                                </a>

                                <div
                                    class="merchantRatings"
                                    v-show="record.mrRating !== null"
                                >
                                    <span
                                        class="rating"
                                        v-html="showStars(record.mrRating)"
                                    ></span>
                                </div>
                                <div
                                    class="calloutExt"
                                    v-html="record.calloutExtension"
                                ></div>
                                <div class="reviewExtentions">
                                    <a
                                        :href="record.reviewExtension.url"
                                        class="reviewExtLink"
                                        ><span
                                            class="reviewExt"
                                            v-show="
                                                record.reviewExtension
                                                    .length !== 0
                                            "
                                            v-html="record.reviewExtension.text"
                                        ></span
                                    ></a>
                                </div>

                                <!--should only appear on top add, record 0-->
                                <div
                                    id="top-add-annotation-record_0"
                                    class="topAdAnnot"
                                    v-html="record.topAdExtension.text"
                                ></div>
                                <!--end top add annotation-->

                                <div
                                    :id="
                                        `special-top-record-description_${index}`
                                    "
                                    class="specialRecordDescription recordDescription"
                                >
                                    <span v-html="record.description"></span>
                                </div>
                                <div
                                    class="smartAnnot"
                                    v-html="record.smart_annotaiton"
                                ></div>
                                <div
                                    class="fourthLineAnnot"
                                    v-html="record.ad4thLineAnnotation"
                                ></div>

                                <div
                                    v-if="
                                        record.site_links &&
                                            record.site_links.length > 0
                                    "
                                    :id="
                                        `special-top-record-site-links-wrapper_${index}`
                                    "
                                    class="specialRecordSiteLinksWrapper"
                                >
                                    <div
                                        v-for="(link,
                                        index2) in record.site_links"
                                        :key="index2"
                                        :id="
                                            `special-top-record-site-link_${index}-${index2}`
                                        "
                                        class="specialRecordSiteLink"
                                    >
                                        <a
                                            :id="
                                                `special-top-record-site-link-tag_${index}-${index2}`
                                            "
                                            class="specialRecordSiteLinkTag"
                                            target="_blank"
                                            :href="link.url"
                                        >
                                            <span v-html="link.text" />
                                        </a>

                                        <div
                                            class="enhancedSitelinks"
                                            v-html="link.desc1"
                                        ></div>
                                        <div
                                            class="enhancedSitelinks"
                                            v-html="link.desc2"
                                        ></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        v-if="searchCompleted && organicRecords"
                        id="organic-results-wrapper"
                        class="organicResultsWrapper"
                    >
                        <div
                            id="organic-results-title"
                            class="organicResultsTitle"
                        ></div>
                        <div
                            id="organic-results-inner-wrapper"
                            class="organicResultsInnerWrapper"
                        >
                            <div
                                v-for="(record, index) in organicRecords"
                                :key="index"
                                :id="`organic-record_${index}`"
                                class="organicRecord record"
                                @click="sendXMLTracking(3)"
                            >
                                <a
                                    :id="`organic-results-link_${index}`"
                                    class="organicResultsLink"
                                    target="_blank"
                                    :href="record.url"
                                >
                                    <div
                                        :id="`organic-record-url_${index}`"
                                        class="organicRecordUrl recordUrl"
                                    >
                                        {{ record.display_url }}
                                    </div>
                                    <div
                                        :id="`organic-record-title_${index}`"
                                        class="organicRecordTitle recordTitle"
                                    >
                                        <span v-html="record.title"></span>
                                    </div>

                                    <div
                                        :id="
                                            `organic-record-description_${index}`
                                        "
                                        class="organicRecordDescription recordDescription"
                                    >
                                        <span
                                            v-html="record.description"
                                        ></span>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div
                        v-if="searchCompleted && bottomAds"
                        id="special-results-wrapper-bottom"
                        class="specialResultsWrapper"
                    >
                        <div
                            id="special-bottom-results-title"
                            class="specialResultsTitle"
                        >
                            {{ getAdsTitleText() }}
                        </div>
                        <div
                            id="special-bottom-results-inner-wrapper"
                            class="specialResultsInnerWrapper"
                        >
                            <div
                                v-for="(record, index) in bottomAds"
                                :key="index"
                                :id="`special-bottom-record_${index}`"
                                class="specialRecord record"
                                @click="sendXMLTracking(2)"
                            >
                                <a
                                    :id="
                                        `special-bottom-results-primary-area_${index}`
                                    "
                                    class="specialResultsPrimaryArea"
                                    target="_blank"
                                    :href="record.url"
                                >
                                    <div
                                        :id="
                                            `special-bottom-record-title_${index}`
                                        "
                                        class="specialRecordTitle recordTitle"
                                    >
                                        <span v-html="record.title"></span>
                                    </div>
                                    <div
                                        :id="
                                            `special-bottom-record-url_${index}`
                                        "
                                        class="specialRecordUrl recordUrl"
                                    >
                                        {{ record.display_url }}
                                    </div>
                                    <div
                                        :id="
                                            `special-bottom-record-description_${index}`
                                        "
                                        class="specialRecordDescription recordDescription"
                                    >
                                        <span
                                            v-html="record.description"
                                        ></span>
                                    </div>
                                </a>
                                <div
                                    v-if="
                                        record.site_links &&
                                            record.site_links.length > 0
                                    "
                                    :id="
                                        `special-bottom-record-site-links-wrapper_${index}`
                                    "
                                    class="specialRecordSiteLinksWrapper"
                                >
                                    <div
                                        v-for="(link,
                                        index2) in record.site_links"
                                        :key="index2"
                                        :id="
                                            `special-bottom-record-site-link_${index}-${index2}`
                                        "
                                        class="specialRecordSiteLink"
                                        @click.prevent="goToUrl(link.url, true)"
                                    >
                                        <a
                                            :id="
                                                `special-bottom-record-site-link-tag_${index}-${index2}`
                                            "
                                            class="specialRecordSiteLinkTag"
                                            target="_blank"
                                            :href="link.url"
                                        >
                                            <span v-html="link.text"
                                        /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        v-if="
                            fullSearchResponse &&
                                fullSearchResponse.suggestion &&
                                fullSearchResponse.suggestion.length > 0
                        "
                        class="moreSponsors"
                    >
                        <p>
                            Searches related to
                            <span id="moreLinks" v-html="this.query"></span>
                        </p>
                        <div class="sponsorGrid">
                            <div
                                v-for="(sugg,
                                index3) in fullSearchResponse.suggestion"
                                :key="index3"
                            >
                                <span
                                    @click="onRelatedClick(sugg)"
                                    class="spLink"
                                    ><img
                                        src="/img/common/icon_search_more.svg"
                                        class="bluemag"/>
                                    <span
                                        class="sLink"
                                        v-html="getCustomBoldedString(sugg)"
                                    >
                                    </span
                                ></span>
                            </div>
                        </div>
                    </div>

                    <div
                        id="web-pagination-wrapper"
                        class="webPaginagtionWrapper"
                    >
                        <div
                            v-if="prevLink"
                            id="web-prev-button"
                            class="paginationButton"
                            @click="paginate(prevLink)"
                        >
                            Previous
                        </div>
                        <div
                            v-if="nextLink"
                            id="web-next-button"
                            class="paginationButton"
                            @click="paginate(nextLink)"
                        >
                            Next
                        </div>
                    </div>
                </div>
            </div>
            <!--PLA-->

            <div
                v-if="
                    fullSearchResponse &&
                        fullSearchResponse.pla &&
                        fullSearchResponse.pla.length > 0
                "
                class="webSerpSide"
                id="pla-ads"
            >
                <div id="ad-title">
                    Ads • <span class="ad-kw" v-html="query"></span>
                </div>

                <div
                    v-for="(pla, index4) in fullSearchResponse.pla"
                    :key="index4"
                >
                    <div
                        class="pla-item"
                        :class="
                            `${isHovering == 'true' + index4 ? 'zoom' : ''}`
                        "
                        @mouseenter="isHovering = 'true' + index4"
                        @mouseleave="isHovering = ''"
                    >
                        <a class="thumb" :href="pla.url" target="_blank">
                            <div class="imageBox">
                                <div class="backplate"></div>
                                <img :src="pla.imageUrl" class="pla-img" />
                            </div>

                            <div
                                :class="
                                    `${
                                        isHovering == 'true' + index4
                                            ? 'ad-texts-zoom'
                                            : 'ad-texts'
                                    }`
                                "
                            >
                                <a
                                    :href="pla.url"
                                    target="_blank"
                                    class="ad-info"
                                >
                                    <div
                                        :class="
                                            `${
                                                isHovering == 'true' + index4
                                                    ? 'pla-title-zoom zoom-copy'
                                                    : 'pla-title'
                                            }`
                                        "
                                        v-html="pla.originalTitle"
                                    ></div
                                ></a>
                                <div class="pla-price-container">
                                    <div
                                        class="pla-price"
                                        v-if="pla.dealPrice"
                                        v-html="pla.dealPrice"
                                    ></div>
                                    <div
                                        class="pla-price line-through"
                                        v-if="pla.dealPrice"
                                        v-html="pla.originalPrice"
                                    ></div>
                                    <div
                                        class="pla-price"
                                        v-if="!pla.dealPrice"
                                        v-html="pla.originalPrice"
                                    ></div>
                                </div>
                                <a
                                    class="pla-seller"
                                    :href="pla.url"
                                    target="_blank"
                                    v-html="pla.sellername"
                                ></a>
                            </div>
                        </a>
                    </div>
                </div>
            </div>

            <!--end PLA-->
            <div v-else id="web-serp-side" class="webSerpSide">
                <div
                    v-if="searchCompleted && sideAds"
                    id="special-results-wrapper-side"
                    class="specialResultsWrapper"
                >
                    <div
                        id="special-side-results-title"
                        class="specialResultsTitle"
                    >
                        {{ getAdsTitleText() }}
                    </div>
                    <div
                        id="special-side-results-inner-wrapper"
                        class="specialResultsInnerWrapper"
                    >
                        <div
                            v-for="(record, index) in sideAds"
                            :key="index"
                            :id="`special-side-record_${index}`"
                            class="specialRecord record"
                            @click="sendXMLTracking(2)"
                        >
                            <a
                                :id="
                                    `special-side-results-primary-area_${index}`
                                "
                                class="specialResultsPrimaryArea"
                                target="_blank"
                                :href="record.url"
                            >
                                <div
                                    :id="`special-side-record-url_${index}`"
                                    class="specialRecordUrl recordUrl"
                                >
                                    {{ record.display_url }}
                                </div>
                                <div
                                    :id="`special-side-record-title_${index}`"
                                    class="specialRecordTitle recordTitle"
                                >
                                    <span v-html="record.title"></span>
                                </div>

                                <div
                                    :id="
                                        `special-side-record-description_${index}`
                                    "
                                    class="specialRecordDescription recordDescription"
                                >
                                    <span v-html="record.description"></span>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import queryString from "query-string";
import fetchJsonp from "fetch-jsonp";
import { YAHOO_SEARCH_API_URL, CURRENT_HOST } from "@/utility";
import { yahooXMLClickEvent, trackGA } from "@/analytics";
import { getTypeTag } from "@/common";

export default {
    name: "Web_XML",
    props: ["defaultSearchAction", "currentQuery"],
    data() {
        const qs = queryString.parse(window.location.search);
        return {
            query: qs.q || null,
            typeTag: qs.type || null,
            enteredQuery: this.currentQuery || null,
            badSearch: false,
            currentPage: 0,
            searchCompleted: false,
            totalPages: null,
            totalRecords: null,
            fullSearchResponse: null,
            isHovering: false,
            searchAction: null,
        };
    },
    mounted() {
        if (this.defaultSearchAction)
            this.searchAction = this.defaultSearchAction;
        document.title = `Web Search: ${this.query}`;
        this.handleSearchUrl();
    },
    watch: {
        currentQuery(nv) {
            this.enteredQuery = nv;
            this.doSearch(false);
        },
    },
    computed: {
        topAds() {
            if (this.adRecords) {
                if (this.adRecords.length >= 3) {
                    return this.adRecords.slice(0, 3);
                }
                return this.adRecords;
            }
            return null;
        },
        bottomAds() {
            if (this.adRecords) {
                if (this.adRecords.length >= 6) {
                    return this.adRecords.slice(3, 6);
                } else if (this.adRecords.length > this.topAds.length) {
                    return this.adRecords.slice(3, this.adRecords.length);
                }
            }
            return null;
        },
        sideAds() {
            if (this.adRecords && this.topAds && this.bottomAds) {
                if (
                    this.adRecords.length >
                    this.topAds.length + this.bottomAds.length
                ) {
                    const bottomIndex =
                        this.topAds.length + this.bottomAds.length;
                    return this.adRecords.slice(
                        bottomIndex,
                        this.adRecords.length
                    );
                }
            }
            return null;
        },
        adRecords() {
            if (this.fullSearchResponse) return this.fullSearchResponse.ads;
            return null;
        },
        organicRecords() {
            if (this.fullSearchResponse) return this.fullSearchResponse.organic;
            return null;
        },
        prevLink() {
            if (this.fullSearchResponse)
                return this.fullSearchResponse.prevlink;
            return null;
        },
        nextLink() {
            if (this.fullSearchResponse)
                return this.fullSearchResponse.nextlink;
            return null;
        },
    },
    methods: {
        sendXMLTracking(type) {
            yahooXMLClickEvent(type, this.fullSearchResponse);
        },
        getAdsTitleText() {
            return `Ads Related to ${this.enteredQuery}`;
        },
        goToTop() {
            document.getElementById("root").scrollTo(0, 0);
        },
        paginate(link) {
            this.doSearch(link);
            this.goToTop();
        },
        goToUrl(url, openNewTab = false) {
            if (!openNewTab) {
                return window.open(
                    url,
                    window.self !== window.top ? "_parent" : "_self"
                );
            }
            window.open(url, "_blank");
        },
        async freshQuerySearch(fromUrl = false) {
            // close first run
            if (!fromUrl) this.whichPop = false;

            this.badSearch = false;
            this.enteredQuery = this.query;

            await this.doSearch(fromUrl);
        },
        async doSearch(paginationLink = false) {
            window.scrollTo(0, 0);
            const term = this.enteredQuery;

            if (term) {
                this.fullSearchResponse = null;
                this.badSearch = false;

                // this.currentPage = 0;

                this.searchCompleted = false;

                let url = `${YAHOO_SEARCH_API_URL}?serve_url=${CURRENT_HOST}${
                    window.location.pathname
                }&q=${encodeURIComponent(term)}&ads=10&bold=1&type=${this
                    .typeTag || getTypeTag("Y219_F1_201405_")}&search_action=${
                    this.searchAction ? this.searchAction : "default_search"
                }`;

                if (paginationLink) url += `&${paginationLink}`;

                const req = await fetch(url, {});
                if (req.ok) {
                    const res = await req.json();

                    this.fullSearchResponse = res;
                    /*
                    if(res.ads) this.adRecords = res.ads
                    if(res.organic) this.organicRecords = res.organic

                    if(res.prevlink) this.prevLink = res.prevlink
                    if(res.nextlink) this.nextLink = res.nextlink
                    */

                    this.searchCompleted = true;
                } else {
                    this.badSearch = true;
                    this.searchCompleted = true;
                }
            }
        },
        async handleSearchUrl() {
            const qs = queryString.parse(window.location.search);
            const { q, g } = qs;

            if (q && q != "*") {
                this.query = q;
                await this.freshQuerySearch();
                trackGA("view_search_results", {
                    search_term: this.query,
                    search_results:
                        (this.organicRecords && this.organicRecords.length) ||
                        0,
                });
            }
        },
        onRelatedClick(keyword) {
            const currentQueryParams =
                this.$route?.query != undefined
                    ? { ...this.$route.query }
                    : null;
            if (currentQueryParams && this.$router) {
                currentQueryParams.q = keyword;
                this.$router
                    .push({
                        query: currentQueryParams,
                        params: { defaultSearchAction: "related_search" },
                    })
                    .catch(() => {});
            }
        },
        getCustomBoldedString(kw) {
            const query = decodeURIComponent(this.query.toString());
            kw.split(" ").filter(c => {
                if (query.indexOf(c) == -1) kw = kw.replace(c, `<b>${c}</b>`);
            });
            return kw;
        },
        showStars(ratings) {
            let finalRatings;
            if (ratings) {
                finalRatings = "★".repeat(ratings);
                if (ratings < 5) finalRatings += "☆".repeat(5 - ratings);
            }
            return finalRatings;
        },
    },
};
</script>

<style>
@import "./web_xml.css";
</style>
